import { React, styled } from 'x'
import { ThemeProvider } from 'styled-components'
import { assign, toValues } from 'utils'
import { style, fontSize, color, space, width } from 'styled-system'

const log = {
  Do: false,
  Box: false,
  Pipe: false,
}

const breakpoints = {
  xs: '0em',
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
  hd: '100em',
}

const theme = {
  breakpoints: assign(toValues(breakpoints), breakpoints)
}

// const content = style()

const Box = styled.div`
  ${ space }
  ${ width }
  ${ fontSize }
  ${ color }
`

const Block = styled(Box)(props => (log.Box && console.log(props), {
  border: props.color,
}))

const Do = do {
  const A = styled.div`
    ${ p => (log.Do && console.log('A:', p), '') }
    border: 1px solid rebeccapurple;
  `

  const B = styled(A)(p => {
    log.Do && console.log('B:', p)

    return {
      b: true,
    }
  })

  const C = p => {
    log.Do && console.log('C:', p)

    return <B c { ...p } />
  }

  C
}

const Pipe = styled.div`
  ${ p => (log.Pipe && console.log('A:', p), '') }
  border: 3px solid rebeccapurple;
  background: white;
`
|> styled(#)(p => (console.log('styled object:, p'), {
    color: 'rebeccapurple'
  }))

// console.log('Do', Do)
// console.log('Pipe', Pipe)



const Spread = styled.div`
  margin: 20px;
  padding: 20px;
`
  |> styled(#)(p => ({
    color: p.fg,
  }))


export default () => {
  return (
    <ThemeProvider theme={ theme }>
      <div style={{ paddingTop: '200px' }}>
        System!
        <Box
          color={{
            xs: 'rgb(255, 255, 255)',
            sm: 'rgb(215, 215, 215)',
            md: 'rgb(193, 193, 193)',
            lg: 'rgb(160, 160, 160)',
            xl: 'rgb(120, 120, 120)',
            hd: 'rgb(100, 100, 100)',
          }}
        >
          Ola
        </Box>
        <Block
          color={{
            xs: 'rgb(0, 255, 255)',
            sm: 'rgb(0, 215, 215)',
            md: 'rgb(0, 193, 193)',
            lg: 'rgb(0, 160, 160)',
          }}
        >
          Bonjour
        </Block>
        <Do>Do</Do>
        <Pipe>Pipe</Pipe>
        <Spread fg={ 'hotpink' }>Spread</Spread>
      </div>
    </ThemeProvider>
  )
}
